import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Role } from '../../role.model';
import { SetSort } from './role-list.actions';

export interface RoleListStateModel {
  sort: Sort;
}

const defaultState: RoleListStateModel = {
  sort: { active: <keyof Role>'name', direction: 'asc' },
};

@State<RoleListStateModel>({
  name: 'roleListState',
  defaults: defaultState,
})
@Injectable()
export class RoleListState {
  @Selector() static sort(state: RoleListStateModel): Sort {
    return state.sort;
  }

  @Action(SetSort) setSort(ctx: StateContext<RoleListStateModel>, { payload }: SetSort) {
    ctx.patchState({ sort: payload });
  }
}
