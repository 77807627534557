import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import {
  ClearAuth,
  ClearReturnPath,
  SetAuthToken,
  SetPkceVerifier,
  SetReturnPath,
  SetUser,
} from './auth.actions';
import { AuthToken, UserInfo } from './auth.models';

export interface AuthStateModel {
  returnPath?: string;
  token?: AuthToken;
  user?: UserInfo;
  verifier?: string;
}

@State<AuthStateModel>({
  name: 'authState',
})
@Injectable()
export class AuthState {
  @Selector() static returnPath(state: AuthStateModel) {
    return state.returnPath;
  }

  @Selector() static authToken(state: AuthStateModel) {
    return state.token;
  }

  @Selector() static accessToken(state: AuthStateModel) {
    return state.token?.access_token;
  }

  @Selector() static idToken(state: AuthStateModel) {
    return state.token?.id_token;
  }

  @Selector() static refreshToken(state: AuthStateModel) {
    return state.token?.refresh_token;
  }

  @Selector() static user(state: AuthStateModel) {
    return state.user;
  }

  @Selector() static pkceVerifier(state: AuthStateModel) {
    return state.verifier;
  }

  @Selector() static loggedIn(state: AuthStateModel) {
    return isDefined(state.user) && isDefined(state.token) && !isExpired(state.token);
  }

  @Action(SetReturnPath) setReturnPath(ctx: StateContext<AuthStateModel>, action: SetReturnPath) {
    ctx.patchState({ returnPath: action.returnPath });
  }

  @Action(ClearReturnPath) clearReturnPath(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({ returnPath: undefined });
  }

  @Action(SetAuthToken) setContext(ctx: StateContext<AuthStateModel>, action: SetAuthToken) {
    ctx.patchState({ token: action.token });
  }

  @Action(SetUser) setUser(ctx: StateContext<AuthStateModel>, action: SetUser) {
    ctx.patchState({ user: action.user });
  }

  @Action(SetPkceVerifier) setPkceVerifier(
    ctx: StateContext<AuthStateModel>,
    action: SetPkceVerifier,
  ) {
    ctx.patchState({ verifier: action.verifier });
  }

  @Action(ClearAuth) clearAuth(ctx: StateContext<AuthStateModel>) {
    ctx.setState({});
  }
}

function isExpired(token: AuthToken) {
  const expired = Date.now() > token.expires_at;
  return expired;
}
