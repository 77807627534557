import { AuthToken, UserInfo } from './auth.models';

export class SetReturnPath {
  static readonly type = '[AuthState] SetReturnPath';
  constructor(public readonly returnPath: string) {}
}

export class ClearReturnPath {
  static readonly type = '[AuthState] ClearReturnPath';
}

export class SetAuthToken {
  static readonly type = '[AuthState] SetAuthToken';
  constructor(public readonly token: AuthToken) {}
}

export class SetUser {
  static readonly type = '[AuthState] SetUser';
  constructor(public readonly user: UserInfo) {}
}

export class SetPkceVerifier {
  static readonly type = '[AuthState] SetPkceVerifier';
  constructor(public readonly verifier: string) {}
}

export class ClearAuth {
  static readonly type = '[AuthState] ClearAuth';
}
