[
  {
    "id": 1,
    "name": "Admin Role 1",
    "description": "Sample Role 1",
    "active": true,
    "type": "Admin",
    "permissions": [],
    "system": true
  },
  {
    "id": 2,
    "name": "Role 2",
    "description": "Sample Role 2",
    "active": true,
    "type": "User",
    "permissions": [],
    "system": false
  }
]
