import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { formUrlEncode, isDefined, isNil } from '@trimble-gcs/common';
import { NgxPermissionsService } from 'ngx-permissions';
import {
  Observable,
  catchError,
  from,
  interval,
  map,
  of,
  share,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { AppState } from '../app-state/app.state';
import { RoleService } from '../roles/role.service';
import { ClearAuth, SetAuthToken, SetPkceVerifier, SetUser } from './auth.actions';
import { AppPermissions, AuthToken, UserInfo } from './auth.models';
import { AuthRoutes } from './auth.routes';
import { AuthState } from './auth.state';
import { PkceProvider } from './pkce.provider';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private renewTokenThreshold = 5;

  constructor(
    private store: Store,
    private pkceProvider: PkceProvider,
    private http: HttpClient,
    private permissionService: NgxPermissionsService,
    private roleService: RoleService,
    private router: Router,
  ) {}

  registerTokenExpiryWatch() {
    //set timer to check periodicly
    interval(60 * 1000)
      .pipe(switchMap(() => this.refreshTokenIfExpired()))
      .subscribe();

    //execute immediatly on register
    return this.refreshTokenIfExpired().pipe(take(1));
  }

  private refreshTokenIfExpired(): Observable<AuthToken | null> {
    const token = this.store.selectSnapshot(AuthState.authToken);
    if (!token?.expires_at) {
      return of(null);
    }

    const expiryUtc = new Date(token.expires_at);
    const currentDate = new Date();
    const minutesUntilExpiry = (expiryUtc.getTime() - currentDate.getTime()) / 1000 / 60;

    if (minutesUntilExpiry >= this.renewTokenThreshold) {
      return of(null);
    }

    return this.refreshToken();
  }

  private refreshToken() {
    return this.pkceProvider.generatePKCE$().pipe(
      switchMap((pkce) => {
        const lastVerifier = this.store.selectSnapshot(AuthState.pkceVerifier);
        const token = this.store.selectSnapshot(AuthState.authToken);
        const { verifier: nextVerifier, challenge: nextChallenge } = pkce;
        const {
          tidSettings: { token_endpoint, client_id },
        } = this.store.selectSnapshot(AppState.settings);

        const body = formUrlEncode({
          grant_type: 'refresh_token',
          client_id,
          code_verifier: lastVerifier,
          code_challenge: nextChallenge,
          code_challenge_method: 'S256',
          refresh_token: token?.refresh_token,
        });

        return this.http
          .post<AuthToken>(token_endpoint, body, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          })
          .pipe(
            tap((token: AuthToken) => {
              token.expires_at = Date.now() + token.expires_in * 1000;
              this.store.dispatch(new SetAuthToken(token));
              this.store.dispatch(new SetPkceVerifier(nextVerifier));
            }),
            catchError(() => {
              return this.store.dispatch(ClearAuth).pipe(
                switchMap(() => from(this.router.navigate([AuthRoutes.Login]))),
                map(() => null),
              );
            }),
          );
      }),
    );
  }

  loadUser(): Observable<UserInfo | null> {
    const token = this.store.selectSnapshot(AuthState.authToken);

    return isNil(token)
      ? of(null)
      : this.getUserInfo(token.id_token).pipe(
          catchError((err: unknown) => {
            return err instanceof HttpErrorResponse && err.status === HttpStatusCode.Unauthorized
              ? this.store.dispatch(ClearAuth).pipe(
                  switchMap(() => from(this.router.navigate([AuthRoutes.Login]))),
                  map(() => null),
                )
              : throwError(() => err);
          }),
        );
  }

  signIn(): Observable<UserInfo | void> {
    const token = this.store.selectSnapshot(AuthState.authToken);
    const { production: production } = this.store.selectSnapshot(AppState.settings);

    return isNil(token)
      ? this.authorize()
      : this.getUserInfo(token.id_token).pipe(
          catchError((err: unknown) => {
            if (err instanceof HttpErrorResponse && err.status === HttpStatusCode.Unauthorized) {
              return this.authorize();
            }
            if (!production) throw err;
            else throw new Error('Something went wrong.');
          }),
        );
  }

  private authorize() {
    return this.pkceProvider.generatePKCE$().pipe(
      switchMap((pkce) => {
        const url = this.authorizeUrl(pkce.challenge, false);
        return this.store
          .dispatch(new SetPkceVerifier(pkce.verifier))
          .pipe(tap(() => location.replace(url)));
      }),
    );
  }

  codeTokenExchange(code: string): Observable<AuthToken> {
    return this.pkceProvider.generatePKCE$().pipe(
      switchMap((pkce) => {
        const lastVerifier = this.store.selectSnapshot(AuthState.pkceVerifier);
        const { verifier: nextVerifier, challenge: nextChallenge } = pkce;
        const {
          tidSettings: { token_endpoint, client_id, redirect_uri, tenantDomain },
        } = this.store.selectSnapshot(AppState.settings);

        const body = formUrlEncode({
          grant_type: 'authorization_code',
          code,
          tenantDomain,
          redirect_uri,
          client_id,
          code_verifier: lastVerifier,
          code_challenge: nextChallenge,
          code_challenge_method: 'S256',
        });

        return this.http
          .post<AuthToken>(token_endpoint, body, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          })
          .pipe(
            tap((token: AuthToken) => {
              token.expires_at = Date.now() + token.expires_in * 1000;
              this.store.dispatch(new SetAuthToken(token));
              this.store.dispatch(new SetPkceVerifier(nextVerifier));
            }),
          );
      }),
    );
  }

  getUserInfo(id_token: string): Observable<UserInfo> {
    const {
      tidSettings: { userinfo_endpoint },
    } = this.store.selectSnapshot(AppState.settings);

    return this.http
      .get<UserInfo>(userinfo_endpoint, {
        headers: { Authorization: `Bearer ${id_token}` },
      })
      .pipe(
        switchMap((user) => {
          return this.loadUserPermissions(user);
        }),
        switchMap((user) => {
          return this.store.dispatch(new SetUser(user)).pipe(map(() => user));
        }),
      );
  }

  loadUserPermissions(user: UserInfo) {
    /**
     * TODO: This is temporary until we have a role management
     * service, for now assume admin role.
     */
    return this.roleService.getAdminRole$().pipe(
      map((role) => {
        this.permissionService.loadPermissions(role.permissions);
        return { ...user, role };
      }),
    );
  }

  signOut(): Observable<void> {
    return this.revokeToken().pipe(
      catchError((err: unknown) => {
        return of(err);
      }),
      switchMap(() => {
        const url = this.logoutUrl();
        return this.store.dispatch(ClearAuth).pipe(tap(() => location.replace(url)));
      }),
    );
  }

  private revokeToken(): Observable<void> {
    const {
      tidSettings: { revocation_endpoint, client_id },
    } = this.store.selectSnapshot(AppState.settings);
    const refresh_token = this.store.selectSnapshot(AuthState.refreshToken);
    const code_verifier = this.store.selectSnapshot(AuthState.pkceVerifier);

    const body = formUrlEncode({
      client_id,
      token: refresh_token,
      token_type_hint: 'refresh_token',
      code_verifier,
    });

    return this.http.post<void>(revocation_endpoint, body, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
  }

  private authorizeUrl(code_challenge: string, promptNone = false) {
    const {
      tidSettings: { authorization_endpoint, client_id, app_name, redirect_uri },
    } = this.store.selectSnapshot(AppState.settings);

    const params = {
      client_id: client_id,
      response_type: 'code',
      scope: `openid ${app_name}`,
      redirect_uri: redirect_uri,
      code_challenge: code_challenge,
      code_challenge_method: 'S256',
      prompt: promptNone ? 'none' : undefined,
    };

    const url = this.buildUrl(authorization_endpoint, params);
    return url;
  }

  private logoutUrl() {
    const {
      tidSettings: { end_session_endpoint, post_logout_redirect_uri },
    } = this.store.selectSnapshot(AppState.settings);

    const id_token_hint = this.store.selectSnapshot(AuthState.idToken);
    return this.buildUrl(end_session_endpoint, { id_token_hint, post_logout_redirect_uri });
  }

  private buildUrl(uri: URL | string, params: ArrayLike<unknown> | { [s: string]: unknown } = {}) {
    const url = new URL(uri);
    Object.entries(params).forEach(([key, value]) => {
      if (isDefined(value)) url.searchParams.append(key, String(value));
    });
    return url;
  }

  hasAnyPermission(...permissions: AppPermissions[]): boolean {
    const ngxPermissionsObject = this.permissionService.getPermissions();
    return permissions.some((key) => !!ngxPermissionsObject[key]);
  }

  hasAllPermissions(...permissions: AppPermissions[]): boolean {
    const ngxPermissionsObject = this.permissionService.getPermissions();
    return permissions.every((key) => !!ngxPermissionsObject[key]);
  }

  hasAnyPermission$(...permissions: AppPermissions[]): Observable<boolean> {
    return this.permissionService.permissions$.pipe(
      map((ngxPermissionsObject) => {
        return permissions.some((key) => !!ngxPermissionsObject[key]);
      }),
      share(),
    );
  }

  hasAllPermission$(...permissions: AppPermissions[]): Observable<boolean> {
    return this.permissionService.permissions$.pipe(
      map((ngxPermissionsObject) => {
        return permissions.every((key) => !!ngxPermissionsObject[key]);
      }),
      share(),
    );
  }
}
