import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Select, Store } from '@ngxs/store';
import { Observable, map, of, tap } from 'rxjs';
import { AppPermissions } from '../auth/auth.models';
import { SetSort } from './components/role-list/role-list.actions';
import { RoleListState } from './components/role-list/role-list.state';
import { Role, RoleType } from './role.model';
import rolesSampleData from './roles-sample-data.json';

@Injectable({ providedIn: 'root' })
export class RoleService {
  @Select(RoleListState.sort) sort$!: Observable<Sort>;

  //TODO: temporary until api is in place
  sampleData: Role[] = rolesSampleData as Role[];

  constructor(private store: Store) {}

  //TODO: temporary until api is in place
  private getSampleData$(): Observable<Role[]> {
    return of(this.sampleData);
  }

  getRoles$(): Observable<Role[]> {
    return this.getSampleData$();
  }

  getRole$(roleId: number): Observable<Role> {
    const role = this.getSampleData$().pipe(
      map((roles) => {
        return roles.find((role) => role.id == roleId);
      }),
    );

    return role as Observable<Role>;
  }

  //todo: setup a role service to manage user permissions
  //until then everyone gets admin by default
  getAdminRole$(): Observable<Role> {
    const role = this.getSampleData$().pipe(
      map((roles) => {
        return roles.find((role) => role.type == RoleType.Admin);
      }),
      tap((role) => {
        if (!role) {
          return;
        }
        //give the role full permissions
        role.permissions = Object.values(AppPermissions);
      }),
    );

    return role as Observable<Role>;
  }

  setSort(sort: Sort) {
    this.store.dispatch(new SetSort(sort));
  }
}
