export enum RoleType {
  Admin = 'Admin',
  User = 'User',
}

export interface Role {
  id: number;
  name: string;
  description: string;
  active: boolean;
  type: RoleType;
  permissions: string[];
  system: boolean;
}
