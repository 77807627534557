import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { RegionState, RegionStateModel } from '../region/region.state';
import { SetSettings } from './app.actions';
import { AppSettings } from './app.models';

export interface AppStateModel {
  settings: AppSettings;
}

@State<AppStateModel>({
  name: 'appState',
})
@Injectable()
export class AppState {
  @Selector() static settings(state: AppStateModel) {
    return state.settings;
  }

  @Selector([AppState, RegionState]) static endpoints(
    state: AppStateModel,
    regionState: RegionStateModel,
  ) {
    let endpoints = state.settings.endpoints;

    if (isDefined(regionState.regions)) {
      regionState.regions.forEach((region) => {
        endpoints[region.regionCode] = region.endpoint;
      });
    }

    return endpoints;
  }

  @Action(SetSettings) setSettings(ctx: StateContext<AppStateModel>, action: SetSettings) {
    ctx.patchState({ settings: action.appSettings });
  }
}
